/* eslint-disable indent */
require('core-js/stable');
require('regenerator-runtime/runtime');

const MeliGA = require('./resources/MeliGA');
const Melidata = require('./resources/Melidata');
const { getTrackingPath } = require('./utils');

class TrackingService {
  /**
   * Send all tracks for view
   * @param {object} dataLanding
   * @param {string} dealPrintId
   * @param {string} landingType
   * @param {boolean} skeleton
   * @param {string} platformId
   * @param {string} siteId
   * @returns {void}
   */
  static async sendAllTracks({ dataLanding, dealPrintId, landingType, skeleton, platformId, siteId }) {
    try {
      const homesCategories = dataLanding.vertical === 'CATEGORIES' && platformId === 'ML';
      const isMeliCoins = dataLanding.vertical === 'COINS';
      const isMercadoShops = platformId === 'MS';
      Melidata.addMelidataDealid(dataLanding.deal_id);
      if (skeleton) {
        const { meliGA } = getTrackingPath(dataLanding, landingType);
        const trackingType = homesCategories ? 'categories' : 'deals';
        meli_ga('set', 'page', `${meliGA.section[trackingType]}/${meliGA.page[trackingType]}`);
      }
      if (homesCategories) {
        MeliGA.sendCategoriesTracks(dataLanding, dealPrintId);
        Melidata.sendCategoriesTracks(dataLanding, dealPrintId, landingType);
      } else if (isMercadoShops) {
        MeliGA.sendMercadoShopsTracks(dataLanding, dealPrintId, landingType);
        Melidata.sendMercadoShopsTracks(dataLanding, dealPrintId, landingType);
      } else if (isMeliCoins) {
        MeliGA.sendMeliCoinsTracks(dataLanding, dealPrintId, landingType);
        Melidata.sendMeliCoinsTracks(dataLanding, dealPrintId, landingType);
      } else {
        await Promise.all([
          MeliGA.sendDealsTracks(dataLanding, dealPrintId, landingType, siteId),
          Melidata.sendDealsTracks(dataLanding, dealPrintId, landingType, siteId),
        ]);
      }
    } catch (error) {
      const { stack, message } = error;
      const tags = {
        stack,
        message,
        siteId,
        url: dataLanding.url,
      };

      window.newrelic.noticeError(error, tags);
    }
  }
}

module.exports = TrackingService;
