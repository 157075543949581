require('core-js/stable');
require('regenerator-runtime/runtime');

const { formatLandingNameByType, getTrackingPath, retrieveCIDItemsIds } = require('../utils');

class Melidata {
  /**
   * Generate the event data dynamically according to the vertical and the business unit
   * @param {object} dataLanding
   * @param {string} landingType
   * @returns {string}
   */
  static generateDynamicEventData(dataLanding, landingType) {
    const { site, metrics, folder, type = null, url = null, vertical } = dataLanding;
    const isMCoins = ['COINS', 'MP_COINS'].includes(vertical);
    const landingName = site.analytics.toUpperCase();
    const businessUnit = isMCoins ? 'crypto' : (metrics && metrics.bu) || 'all';
    const landingVertical = (metrics && metrics.vertical) || 'default';

    return {
      module: businessUnit.toLowerCase(),
      group: landingVertical.toLowerCase(),
      content: formatLandingNameByType(landingName, type || landingType, url, folder)
        .toLowerCase()
        .replace('/', ''),
    };
  }

  /**
   * Send all tracks for categories
   * @param {object} dataLanding
   * @returns {void}
   */
  static sendCategoriesTracks(dataLanding, dealPrintId, landingType) {
    const { metrics, version = {} } = dataLanding;
    const trackingPath = getTrackingPath(dataLanding, landingType);

    if (typeof melidata === 'function') {
      melidata('send', 'view', {
        path: trackingPath.melidata.categories.view.toLowerCase(),
        event_data: {
          deal_print_id: dealPrintId,
          ...metrics.category,
          last_modified: version.last_modified || '',
        },
      });
    }
  }

  /**
   * Send all tracks for melicoins
   * @param {object} dataLanding
   * @returns {void}
   */
  static sendMeliCoinsTracks(dataLanding, landingType) {
    const trackingPath = getTrackingPath(dataLanding, landingType);
    const dynamicEventData = Melidata.generateDynamicEventData(dataLanding, landingType);

    if (typeof melidata === 'function') {
      melidata('send', 'view', {
        path: trackingPath.melidata.melicoins.toLowerCase(),
        event_data: {
          crypto: 'melicoin',
          ...dynamicEventData,
          context: 'normal-landing',
        },
      });
    }
  }

  /**
   * Send all tracks for MercadoShops
   * @param {object} dataLanding
   * @returns {void}
   */
  static sendMercadoShopsTracks(dataLanding, _dealPrintId, landingType) {
    const { msRef } = dataLanding;
    const trackingPath = getTrackingPath(dataLanding, landingType);

    if (typeof melidata === 'function') {
      melidata('send', 'view', {
        path: trackingPath.melidata.mercadoshops,
        event_data: {
          ref: msRef,
          shop_id: -1,
          shop_domain: 'NON_SET',
          shop_name: 'NON_SET',
        },
      });
      document.cookie = `ref=${msRef};`;
    }
  }

  /**
   * Send all tracks for deals (melidata and analytics) append extra content for CID
   * @param {object} dataLanding
   * @param {string} dealPrintId
   * @param {string} landingType
   * @returns {void}
   */
  static async sendDealsTracks(dataLanding, dealPrintId, landingType, siteId) {
    const { components, isTakeover, deal_id, containers } = dataLanding;
    const merchEngineMS = components.find(component => component.component_name === 'MainSliderPlacement');
    const carrouselItemsIds = components.filter(component => component.component_name === 'CarouselDynamic');
    const sendIds = carrouselItemsIds.length
      ? await retrieveCIDItemsIds(dataLanding.clientSide, carrouselItemsIds, siteId)
      : 'NONE';
    const trackingPath = getTrackingPath(dataLanding, landingType);
    const freshIds =
      merchEngineMS?.items?.map(m => m.data.event_data?.content_id).filter(i => i?.includes('FRESH_HIBRIDO_')) ?? [];
    const freshInitiative = (!!freshIds.length && freshIds.shift().split('FRESH_HIBRIDO_')[1]) || '';
    const dynamicEventData = Melidata.generateDynamicEventData(dataLanding, landingType);

    const contextLanding = isTakeover
      ? 'takeover-landing'
      : `normal-landing${freshInitiative ? `-${freshInitiative}` : ''}`;

    const eventData = {
      deal_print_id: dealPrintId,
      items_ids: sendIds,
      context: contextLanding,
    };

    eventData.containers = containers ?? [];

    if (merchEngineMS) {
      eventData.real_estates = {
        main_slider: Object.fromEntries(merchEngineMS.items.map((item, idx) => [idx, item.data.event_data])),
      };
    }
    const track = {
      event_data: { ...eventData, ...dynamicEventData },
      path: trackingPath.melidata.deals,
    };
    if (merchEngineMS) track.experiments = merchEngineMS.experiments;

    if (typeof melidata === 'function') {
      if (deal_id) {
        return melidata('send', 'view', {
          ...track,
          event_data: {
            ...track.event_data,
            filters: {
              deal_id,
            },
          },
        });
      }
      return melidata('send', 'view', track);
    }
  }

  /**
   * Append to the track melidata deal_id if the landing contains
   * @param {string} deal_id
   * @returns {void}
   */
  static addMelidataDealid(deal_id) {
    if (typeof melidata === 'function' && deal_id) {
      melidata('add', 'deal_id', deal_id);
    }
  }
}

module.exports = Melidata;
