// eslint-disable-next-line import/no-extraneous-dependencies
const React = require('react');
const PropTypes = require('prop-types');
const { Head } = require('nordic/head');
const Page = require('../../components/Page');
const { Script } = require('nordic/script');
const Style = require('nordic/style');
const serialize = require('serialize-javascript');
const MeliGA = require('nordic/analytics/meli-ga');
const { MelidataTrack } = require('nordic/melidata/melidata-track');
const ErrorBoundary = require('@components/ErrorBoundary');
const Hotjar = require('../../components/Hotjar');
const OpenGraph = require('../../components/OpenGraph');
const Skeleton = require('../../components/Skeletons/hub');
const { fetchDataForWebView, openDeepLinkWebView, traceErrorWebView } = require('@utils/webviews');
const { seoDescription, seoTitleTemplate } = require('@utils/helpers');
const { componentListHub } = require('@utils/componentList');
const Reader = require('../../components/Reader');
const { LandingProvider } = require('@splinter/context');
const TrackingService = require('@services/Tracking/TrackingService');
const MeliGaResource = require('@services/Tracking/resources/MeliGA');
const MelidataResource = require('@services/Tracking/resources/Melidata');
const { getTrackingPath } = require('@services/Tracking/utils');
const RedirectApp = require('redirect-app-web');

const servicesConfig = { id: 1540, pageVertical: 'SERVICES' };

class View extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dataLanding: props.dataLanding,
    };
  }

  /* istanbul ignore next */
  componentDidMount() {
    const {
      dataLanding,
      dataLanding: { url, isTakeover },
    } = this.state;
    const { dealPrintId, landingType, skeleton, platformId, siteId, webView, osName } = this.props;

    /** PTR bug workaround for native HTO */
    if (typeof window !== 'undefined' && isTakeover && webView && osName === 'android') {
      try {
        window.scrollBy(0, 1);
      } catch (error) {
        // nothing
      }
    }

    if (skeleton) {
      // eslint-disable-next-line no-shadow
      fetchDataForWebView(url).then(({ error, dataLanding }) => {
        if (!error) {
          this.setState({ dataLanding }, () => {
            TrackingService.sendAllTracks({
              dataLanding,
              dealPrintId,
              landingType,
              skeleton,
              platformId,
              siteId,
            });
          });
          if (typeof meli_hotjar === 'function') {
            meli_hotjar();
          }
        } else {
          traceErrorWebView(error);
          openDeepLinkWebView('meli://home');
        }
      });
    } else {
      TrackingService.sendAllTracks({
        dataLanding,
        dealPrintId,
        landingType,
        skeleton: null,
        platformId,
        siteId,
      });
    }
  }

  render() {
    const { dataLanding } = this.state;
    const {
      siteId,
      siteName,
      platformId,
      nativeAppName,
      hotjar,
      lazyLoad,
      webView,
      webp,
      lowEnd,
      locale,
      deviceType,
      serverTime,
      pixelId,
      osVersion,
      osName,
      isLegacyBrowser,
      xCanonical,
      xExperimentName,
      xExperimentVariant,
      googleTagManager,
      showDealParam,
      dealPrintId,
      xDebugHotjar,
      d2id,
      infoUser,
      landingType,
      skeleton,
      supportNewPdp,
      supportAddToCart,
      inlineCss,
      openInApp,
      dpr,
      platformType,
      jsDisabled,
      isIndexable,
      nonce,
      i18n,
      translations,
    } = this.props;
    const preloadedState = {
      siteId,
      siteName,
      platformId,
      nativeAppName,
      hotjar,
      lazyLoad,
      webView,
      webp,
      lowEnd,
      locale,
      deviceType,
      serverTime,
      pixelId,
      osVersion,
      osName,
      isLegacyBrowser,
      xCanonical,
      xExperimentName,
      xExperimentVariant,
      googleTagManager,
      showDealParam,
      dealPrintId,
      xDebugHotjar,
      d2id,
      infoUser,
      landingType,
      skeleton,
      supportNewPdp,
      supportAddToCart,
      inlineCss,
      dpr,
      platformType,
      dataLanding,
      nonce,
      i18n,
      translations,
    };

    const { name, theme, openGraph, seo, isSkeletonMock, nativeTitle } = dataLanding;
    const seoTitle = seo?.meta?.title || seoTitleTemplate(name, siteId, siteName);
    const defaultTitle = webView ? nativeTitle : seoTitle;
    const title = defaultTitle || name;
    const wrapperClassName = theme ? `${theme} hub` : 'hub';
    const defaultDescription = seoDescription(name, siteId);
    const description = seo?.meta?.description ?? defaultDescription;
    const canonical = xCanonical || seo.canonical;
    const experiment = { [xExperimentName]: xExperimentVariant };
    const dimensions = { melidataExperiments: JSON.stringify([experiment]) };
    const pathDeals = MeliGaResource.generateDynamicPath(dataLanding, landingType);
    const trackingPath = getTrackingPath(pathDeals);
    const { meliGA, melidata } = trackingPath;
    const isCategory = dataLanding.vertical === 'CATEGORIES';
    const trackingType = isCategory ? 'categories' : 'deals';
    const meliDataPath = isCategory ? melidata[trackingType].view : melidata[trackingType];
    const trackingMD = {
      dynamicInfo: MelidataResource.generateDynamicEventData(dataLanding, landingType),
      context: dataLanding?.isTakeover ? 'takeover-landing' : 'normal-landing',
    };

    return (
      <Page name="hub" className={wrapperClassName} state={preloadedState}>
        <ErrorBoundary i18n={i18n}>
          {openInApp && openInApp.shouldRedirect && <RedirectApp {...openInApp} />}
          <MeliGA
            siteId={siteId}
            platform={platformId}
            section={meliGA.section[trackingType]}
            page={meliGA.page[trackingType]}
            dimensions={xExperimentName ? dimensions : undefined}
            business={meliGA.business}
            pageVertical={
              isCategory && dataLanding.metrics.category.category_id === `${siteId}${servicesConfig.id}`
                ? servicesConfig.pageVertical
                : meliGA.pageVertical
            }
            secure
            isDeferred
          />
          <MelidataTrack path={meliDataPath} experiments={xExperimentName ? experiment : undefined} isDeferred />
          <Hotjar {...(dataLanding.hotjar || hotjar)} debug={xDebugHotjar} d2id={d2id} isDeferred={skeleton} isHub />
          <Head>
            <title>{title}</title>
            <link rel="preconnect" href="https://js-agent.newrelic.com" />
            <link rel="preconnect" href="https://connect.facebook.net" />
            {(webView || dataLanding.clientSide) && (
              <Script src="@frontend-performance/image-lazy-loading/src.js" on="now" inline />
            )}
            {canonical && <link rel="canonical" href={canonical} data-head-react="true" />}
            <meta name="description" content={description} />
            {isIndexable === false && <meta name="robots" content="noindex" />}
            {openGraph && (
              <OpenGraph
                ogUrl={dataLanding.url}
                title={openGraph.title}
                ogImage={openGraph.image}
                ogLocale={locale}
                description={openGraph.description}
                ogSiteName={siteId}
              />
            )}
            {
              // This workaround enable inlineCss on webViews
              Array.isArray(inlineCss) && inlineCss.length && (
                <Style renderChildrenInPlace={false}>{inlineCss.join(' ')}</Style>
              )
            }
          </Head>
          {dataLanding.url === 'www.mercadolibre.com.mx/a/store/loreal-1000-tips-de-piel' && (
            <Script crossOrigin={null} src="https://widget.especialistaenbelleza.mx/loader/plazah-pull-widget.js" />
          )}
          {['www.mercadolibre.com.mx/a/store/sun-fresh', 'www.mercadolibre.com.mx/a/store/neutrogena'].includes(
            dataLanding.url,
          ) && <Script crossOrigin={null} src="https://widget.kenvue.pro/loader/plazah-pull-widget.js" />}
          {!isSkeletonMock ? (
            <LandingProvider
              webp={webp}
              dpr={dpr}
              lowEnd={lowEnd}
              lazyLoad={lazyLoad}
              deviceType={deviceType}
              webView={webView}
              showDealParam={showDealParam}
              landingData={dataLanding}
              serverTime={serverTime}
              pixelId={pixelId}
              googleTagManager={googleTagManager}
              siteId={siteId}
              osVersion={osVersion}
              osName={osName}
              uuid={dealPrintId}
              infoUser={infoUser}
              trackingData={trackingPath}
              supportNewPdp={supportNewPdp}
              supportAddToCart={supportAddToCart}
              trackingMD={trackingMD}
              platformType={platformType}
              jsDisabled={jsDisabled}
              title={title}
              nativeAppName={nativeAppName}
            >
              <Reader
                landingData={dataLanding}
                webView={webView}
                deviceType={deviceType}
                componentList={componentListHub}
              />
            </LandingProvider>
          ) : (
            <Skeleton />
          )}
          <Script>
            {`
            window.__PRELOADED_STATE__ = ${serialize(preloadedState, { isJSON: true })};
          `}
          </Script>
          <Script src="https://http2.mlstatic.com/frontend-assets/frontend-viewability/viewability-script.1.1.0.js">
            {`
          window.viewability.setNonceForCSP('${nonce}')
          `}
          </Script>
          {isLegacyBrowser && <Script src="polyfills.js" />}
          <noscript></noscript>
        </ErrorBoundary>
        <Style href="hub.css" critical />
      </Page>
    );
  }
}

const dataLandingPropTypes = {
  dataLanding: PropTypes.shape({
    name: PropTypes.string.isRequired,
    route: PropTypes.string,
    site: PropTypes.shape({
      analytics: PropTypes.string.isRequired,
      seo_text: PropTypes.string,
    }).isRequired,
    seo: PropTypes.shape({
      meta: PropTypes.shape({
        title: PropTypes.string,
      }),
    }).isRequired,
    components: PropTypes.arrayOf(
      PropTypes.shape({
        component_name: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

View.propTypes = {
  ...dataLandingPropTypes,
  webp: PropTypes.bool.isRequired,
  serverTime: PropTypes.number.isRequired,
  deviceType: PropTypes.string.isRequired,
  webView: PropTypes.bool.isRequired,
  lowEnd: PropTypes.bool.isRequired,
  siteId: PropTypes.string.isRequired,
  lazyLoad: PropTypes.shape({ enabled: PropTypes.bool }).isRequired,
  isLegacyBrowser: PropTypes.bool.isRequired,
  platformId: PropTypes.string.isRequired,
  hotjar: PropTypes.shape({}).isRequired,
  pixelId: PropTypes.string.isRequired,
  osVersion: PropTypes.string.isRequired,
  osName: PropTypes.string.isRequired,
  showDealParam: PropTypes.bool,
  xCanonical: PropTypes.string,
  xExperimentName: PropTypes.string,
  xExperimentVariant: PropTypes.string,
  googleTagManager: PropTypes.string,
  dealPrintId: PropTypes.string.isRequired,
  xDebugHotjar: PropTypes.bool.isRequired,
  d2id: PropTypes.string.isRequired,
  infoUser: PropTypes.shape({
    email: PropTypes.string,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    phone: PropTypes.string,
    direction: PropTypes.string,
    dataDenied: PropTypes.bool,
  }),
  openInApp: PropTypes.shape({
    shouldRedirect: PropTypes.bool,
  }),
  dpr: PropTypes.string,
  platformType: PropTypes.string,
  nonce: PropTypes.string,
};

View.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  dataLanding: {
    seo: {
      meta: {
        title: '',
      },
    },
  },
  xCanonical: null,
  xExperimentName: null,
  xExperimentVariant: null,
  googleTagManager: null,
  showDealParam: null,
  infoUser: null,
  openInApp: null,
  dpr: null,
  platformType: null,
};

module.exports = {
  View,
  propTypes: dataLandingPropTypes,
};
