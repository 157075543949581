const componentListHub = [
  'About',
  'Banner',
  'BannerMPlay',
  'BannerPlacement',
  'Benefits',
  'BenefitItem',
  'BlogCarousel',
  'Button',
  'ButtonAnchor',
  'Card',
  'CardPosters',
  'Carousel',
  'CarouselDynamic',
  'CarouselHome',
  'Category',
  'CategoryList',
  'CategoryTrendContainer',
  'CategoryHub',
  'Clockdown',
  'Cobranded',
  'Congrats',
  'ContentDates',
  'CustomBenefit',
  'Coupon',
  'disclaimer',
  'Donate',
  'Faq',
  'RichText',
  'Grid',
  'Header',
  'MainSlider',
  'Item',
  'Js',
  'slides',
  'Special',
  'subtitle',
  'Tracking',
  'Video',
  'Form',
  'LeadsForm',
  'ImageGallery',
  'BannerVideo',
  'AboutCarousel',
  'ButtonAnchor',
  'HeaderAnchor',
  'CobrandedAnchor',
  'CustomBenefitAnchor',
  'dataViewer',
  'BigHeader',
  'AnimatedSteps',
  'Hero',
  'ImageCarousel',
  'TycModal',
  'Title',
  'spacer',
  'MainSliderPlacement',
  'VisFacetedSearch',
  'RedirectModal',
  'sponsorBanner',
];
const componentListMercadoShops = [
  'Clockdown',
  'Benefits',
  'disclaimer',
  'Video',
  'Button',
  'About',
  'Carousel',
  'Grid',
  'Cobranded',
  'CustomBenefit',
  'Coupon',
  'slides',
  'Congrats',
  'subtitle',
  'MainSlider',
  'Tracking',
  'Faq',
  'Js',
  'CarouselDynamic',
];
const componentListMp = [
  'AnimatedSteps',
  'Counter',
  'Tools',
  'Hero',
  'Last',
  'subtitle',
  'TycModal',
  'Tracking',
  'ValueProp',
  'DiscountCards',
  'discountCardsLayout',
  'Video',
  'Collaboration',
  'CtaFixed',
  'Coupon',
  'share',
  'ImageCarousel',
  'Header',
  'HubCards',
  'HubCardsLayout',
  'disclaimer',
  'Form',
  'LeadsForm',
  'Faq',
  'Button',
  'Grid',
  'CarouselDynamic',
];
const componentListSparkle = ['Carousel', 'CarouselDynamic', 'Grid'];

const componentListSpecialImport = [
  'disclaimer',
  'slides',
  'subtitle',
  'dataViewer',
  'spacer',
  'discountCardsLayout',
  'share',
  'sponsorBanner',
];

const componentListMC = [...componentListHub, 'discountCardsLayout', 'Tools', 'CryptoSummary'];

const componentListComunicationals = [...componentListHub];

module.exports = {
  componentListHub,
  componentListSparkle,
  componentListMercadoShops,
  componentListMp,
  componentListSpecialImport,
  componentListMC,
  componentListComunicationals,
};
